import React, { useContext, useEffect, useState } from "react";
import "./ModalTotalCostos.css";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { CotizadorContext } from "../../../contexts/CotizadorContext";
const ModalTotalCostos = ({ position, open, variables }) => {
  const {
    facturaAEmitir,
    setTotalOtrosCostos,
    datosProyectos,
    totalOtrosCostos,
  } = useContext(CotizadorContext);
  const [comisionesFacturacion, setComisionesFacturacion] = useState("");
  const [estructuraServicios, setEstructuraServicios] = useState("");
  const [gastosGenerales, setGastosGenerales] = useState("");
  const [otrosGastosPesos, setOtrosGastosPesos] = useState("");
  const [otrosGastosPorc, setOtrosGastosPorc] = useState("");
  const [impIngresosB, setImpIngresosB] = useState("");
  const [tasaMensual, setTasaMensual] = useState(0);

  useEffect(() => {
    setComisionesFacturacion(totalOtrosCostos.comisionFacturacion || null);
    setGastosGenerales(totalOtrosCostos.gastosGenerales || null);
    setOtrosGastosPesos(totalOtrosCostos.otrosGastosPesos || null);
    setOtrosGastosPorc(totalOtrosCostos.otrosGastosPorc || null);

    let porcentajeIngresosB = limpiarPorcentaje(
      datosProyectos.impuesto.percent
    );
    setImpIngresosB(porcentajeIngresosB);
    const tasaMensualObj = variables.find((obj) => obj.item === "Tasa mensual");
    const estructuraServiciosObj = variables.find(
      (obj) => obj.item === "Estructura de servicios"
    );
    if (tasaMensualObj) {
      setTasaMensual(tasaMensualObj.value);
    }
    if (estructuraServiciosObj) {
      setEstructuraServicios(
        datosProyectos.tipoProyecto !== "Contractor" &&
          datosProyectos.tipoProyecto !== "Tarifa"
          ? estructuraServiciosObj.value * 100
          : 0
      );
    }
  }, []);

  const limpiarPorcentaje = (valor) => {
    return parseFloat(valor?.replace("%", ""));
  };
  /*const obtenerMeses = (name) => {
    const match = name?.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
  };*/
  let calculoComisionesFacturacion =
    (comisionesFacturacion * facturaAEmitir) / 100 || 0;
  let calculoEstructuraServicios =
    (estructuraServicios * facturaAEmitir) / 100 || 0;
  //A CONFIRMAR ORIGEN DE ESTE DATO ESTRUCTURA DE SERVICIOS
  let calculoGastosGenerales = (gastosGenerales * facturaAEmitir) / 100 || 0;
  //  let calculoOtrosGastosPesos = (otrosGastosPesos * facturaAEmitir) / 100 || 0;
  let calculoOtrosGastosPorc = (otrosGastosPorc * facturaAEmitir) / 100 || 0;
  let calculoCostoFinanciero =
    (facturaAEmitir * 1 * 30 * tasaMensual) / 30 || 0;
  let calculoImpDebCred = facturaAEmitir * 1.21 * 0.012 * 0.67 || 0;
  let calculoImpIngresosB = (facturaAEmitir * impIngresosB) / 100 || 0;
  const handleCalcularCosto = () => {
    setTotalOtrosCostos({
      comisionFacturacion: comisionesFacturacion,
      estructuraServicios: estructuraServicios,
      gastosGenerales: gastosGenerales,
      otrosGastosPorc: otrosGastosPorc,
      otrosGastosPesos: otrosGastosPesos,
      costoFinanciero: calculoCostoFinanciero,
      impuestoDebitoCredito: calculoImpDebCred,
      impuestoIngresosBrutos: impIngresosB,
      suma:
        calculoComisionesFacturacion +
        calculoEstructuraServicios +
        calculoGastosGenerales +
        otrosGastosPesos +
        calculoOtrosGastosPorc +
        calculoCostoFinanciero +
        calculoImpDebCred +
        calculoImpIngresosB,
    });
  };

  const onHide = (name) => {
    open(false);
  };
  const footer = (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "end",
        marginTop: "30px",
      }}
    >
      <Button
        label="Cancelar"
        onClick={() => open(false)}
        className="boton-footer-cancelar-modal"
        style={{ color: "#535353", backgroundColor: "transparent" }}
      />
      <Button
        label="Aplicar"
        className="boton-footer-aplicar-modal"
        onClick={() => {
          open(false);
          handleCalcularCosto();
        }}
      />
    </div>
  );

  return (
    <>
      <Dialog
        closable={false}
        draggable={false}
        id="custom-bg-modal"
        style={{
          width: "909px",
          height: "450px",
        }}
        visible={true}
        position={position}
        onHide={() => onHide("onHide")}
      >
        <div className="dialog-title">Total Otros Costos</div>
        <div className="dialog-subtitle">
          A continuación, modificá los items que componen al total de otros
          costos.
        </div>
        <div className="dialog-content">
          <div className="p-field-total-costos">
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "13px",
                marginLeft: "10px",
              }}
              htmlFor="vacationDays"
            >
              Comisiones s/ facturación (Opcional)
            </label>
            <InputNumber
              className="campos-modal-total-costos"
              placeholder="Ingresá el porcentaje de comisión"
              value={comisionesFacturacion}
              suffix="%"
              maxLength={4}
              onValueChange={(e) => setComisionesFacturacion(e.value)}
            />
          </div>
          <div className="p-field-total-costos">
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "13px",
                marginLeft: "10px",
              }}
              htmlFor="vacationDays"
            >
              Estructura de servicios
            </label>
            <InputNumber
              disabled
              value={estructuraServicios}
              className="campos-modal-total-costos"
              placeholder="Ingresá el porcentaje de estructura"
              suffix="%"
              maxLength={4}
            />
          </div>
          <div className="p-field-total-costos">
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "13px",
                marginLeft: "10px",
              }}
              htmlFor="vacationDays"
            >
              Gastos Generales (Opcional)
            </label>
            <InputNumber
              value={gastosGenerales}
              onValueChange={(e) => setGastosGenerales(e.value)}
              className="campos-modal-total-costos"
              placeholder="Ingresá el porcentaje de gastos"
              suffix="%"
              maxLength={4}
            />
          </div>
          <div className="p-field-total-costos">
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "13px",
                marginLeft: "10px",
              }}
              htmlFor="vacationDays"
            >
              Otros Gastos (%)
            </label>
            <InputNumber
              value={otrosGastosPorc}
              onValueChange={(e) => setOtrosGastosPorc(e.value)}
              className="campos-modal-total-costos"
              placeholder="%"
              suffix="%"
              maxLength={4}
            />
          </div>
          <div className="p-field-total-costos">
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "13px",
                marginLeft: "10px",
              }}
              htmlFor="vacationDays"
            >
              Otros Gastos ($)
            </label>
            <InputNumber
              mode="currency"
              currency="ARS"
              locale="es-AR"
              value={otrosGastosPesos}
              onChange={(e) => setOtrosGastosPesos(e.value)}
              className="campos-modal-total-costos"
              placeholder="$"
            />
          </div>
          <div className="p-field-total-costos">
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "13px",
                marginLeft: "10px",
              }}
              htmlFor="vacationDays"
            >
              Costo Financiero
            </label>
            <InputNumber
              mode="currency"
              currency="ARS"
              locale="es-AR"
              disabled
              value={calculoCostoFinanciero}
              className="campos-modal-total-costos"
              placeholder="2,5%"
            />
          </div>
          <section style={{ display: "flex" }}>
            <div
              className="p-field-total-costos"
              style={{ marginRight: "23px" }}
            >
              <label
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "13px",
                  marginLeft: "10px",
                }}
                htmlFor="vacationDays"
              >
                Imp. a Débitos y Créditos
              </label>
              <InputNumber
                mode="currency"
                currency="ARS"
                locale="es-AR"
                disabled
                value={calculoImpDebCred}
                className="campos-modal-total-costos"
                placeholder="10%"
              />
            </div>
            <div className="p-field-total-costos">
              <label
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "13px",
                  marginLeft: "10px",
                }}
                htmlFor="vacationDays"
              >
                Imp. a Ingresos Brutos
              </label>
              <InputNumber
                disabled
                value={impIngresosB}
                className="campos-modal-total-costos"
                placeholder="20%"
                suffix="%"
                maxLength={4}
              />
            </div>
          </section>
          {footer}
        </div>
      </Dialog>
    </>
  );
};
export default ModalTotalCostos;
