import React, { useContext, useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import CalculoTalento from "../CalculoTalento/CalculoTalento";
import TrashButton from "../../../imagenes/trash.png";
import ModalCostoUnitario from "./ModalCostoUnitario";
import "./tablaSeleccionTalento.css";
import { CotizadorContext } from "../../../contexts/CotizadorContext";
import ModalTotalCostos from "./ModalTotalCostos";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const TablaSeleccionTalento = () => {
  const {
    selectedTipoProyecto,
    bases,
    getBases,
    horasDelProyecto,
    duracionDelProyecto,
    setDataTablaTalento,
    dataTablaTalento,
    datosProyectos,
    getCostosVariables,
    getCostosFijos,
    variables,
    setGuardarCoti,
    tipoMoneda,
  } = useContext(CotizadorContext);

  const [data, setData] = useState([]);
  const isContractor = selectedTipoProyecto?.name === "Contractor";
  const [openModal, setOpenModal] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  // const [selectedResources, setSelectedResources] = useState([]);
  const [openModalCostos, setOpenModalCostos] = useState(false);
  const [totalHorasTalento, setTotalHorasTalento] = useState(0);
  const [horasVentaTalento, setHorasVentaTalento] = useState(0);
  const [filaSeleccionada, setFilaSeleccionada] = useState(null);
  const perfiladoProcentaje = [
    "10%",
    "20%",
    "30%",
    "40%",
    "50%",
    "60%",
    "70%",
    "80%",
    "90%",
    "100%",
  ];
  const tiposContratacion = ["Rel. de dependencia", "Freelance"];

  useEffect(() => {
    getBases();
    getCostosVariables();
    getCostosFijos();
    dataTablaTalento?.length > 0
      ? setData(dataTablaTalento)
      : setData([
          {
            id: data.length + 1,
            base: "ICBC",
            rol: "",
            seniority: "",
            cantidad: 0,
            perfilado: 0,
            perfiladoPorcentaje: "",
            sueldoBruto: 0,
            tipoContratacion: "",
            cfteVenta: 0,
            subTotal: 0,
            horaVenta: 0,
            // hora: 0,
            costoUnitario: 0,
            selected: false,
            diasVacaciones: { daysAmount: "14 días" },
            paqueteBeneficios: "",
            costosVariables: 0,
            costosFijos: 0,
            valorHoraCosto: 0,
            cantHoras: 0,
            subtotalCosto: 0,
            ventaTotal: 0,
          },
        ]);
  }, []);

  useEffect(() => {
    setHorasVentaTalento(horasDelProyecto);
  }, [horasDelProyecto]);

  useEffect(() => {
    calcularHorasRestantes();
    const tieneDatos = data.some((item) => {
      const propiedadesConDatos = Object.values(item).filter(
        (valor) =>
          valor !== "" && valor !== null && valor !== 0 && valor !== false
      );
      return propiedadesConDatos.length > 2;
    });

    if (tieneDatos) {
      setGuardarCoti(true);
    } else {
      setGuardarCoti(false);
    }
  }, [data]);

  const calcularHorasRestantes = () => {
    const totalHoras = data.reduce(
      (total, recurso) => total + (recurso.cantHoras * recurso.cantidad || 0),
      0
    );
    setTotalHorasTalento(totalHoras);
    setHorasVentaTalento(horasDelProyecto);
  };

  const onRowEditChange = (e) => {
    setData(e.data);
  };

  const addResource = () => {
    const newResource = {
      id: data.length + 1,
      base: "ICBC",
      rol: "",
      seniority: "",
      cantidad: 0,
      perfilado: 0,
      perfiladoPorcentaje: "",
      sueldoBruto: 0,
      tipoContratacion: "",
      cfteVenta: 0,
      subTotal: 0,
      horaVenta: 0,
      // hora: 0,
      costoUnitario: 0,
      selected: false,
      diasVacaciones: { daysAmount: "14 días" },
      paqueteBeneficios: "",
      costosVariables: 0,
      costosFijos: 0,
      valorHoraCosto: 0,
      cantHoras: 0,
      subtotalCosto: 0,
      ventaTotal: 0,
    };
    setData([...data, newResource]);
  };

  // Comentado para seleccionar múltiples filas y borrar
  // const deleteSelectedResources = () => {
  //     const updatedData = data.filter(resource => !selectedResources.includes(resource));
  //     setData(updatedData);
  //     setSelectedResources([]);
  // };

  const deleteResource = (rowData) => {
    const updatedData = data.filter((resource) => resource.id !== rowData.id);
    setData(updatedData);
  };

  const convertirPorcentaje = (porcentaje) => {
    return parseFloat(porcentaje) / 100;
  };

  const onEditorValueChange = (props, value) => {
    let updatedData = [...data];
    updatedData[props.rowIndex][props.field] =
      value !== null && value !== "" ? value : 0;

    if (props.field === "bandaSalarial" || props.field === "perfilado") {
      const { base, rol, seniority } = updatedData[props.rowIndex];

      if (base && rol && seniority) {
        const seniorities = getSenioritiesForRole(base, rol);
        const selectedSeniority = seniorities.find((s) => s.name === seniority);

        if (selectedSeniority) {
          if (isContractor) {
            updatedData[props.rowIndex]["bandaSalarial"] = value;
          } else if (
            selectedTipoProyecto.name !== "Llave en mano" &&
            selectedTipoProyecto.name !== "Servicios"
          ) {
            updatedData[props.rowIndex]["perfilado"] = value;
          }
        }
      }
    }

    const perfilado = updatedData[props.rowIndex].perfilado || 0;
    const cantidad = updatedData[props.rowIndex].cantidad || 1;
    const cfteVenta = updatedData[props.rowIndex].cfteVenta || 0;
    const bandaSalarialValue =
      parseInt(
        updatedData[props.rowIndex].bandaSalarial
          ?.split(":")[1]
          .replace(/[^\d,]/g, "")
          .replace(",", ".")
      ) || 0;
    const perfiladoPorcentaje =
      updatedData[props.rowIndex].perfiladoPorcentaje || 0;

    if (isContractor) {
      updatedData[props.rowIndex].subTotal =
        updatedData[props.rowIndex].sueldoBruto * cantidad * cfteVenta;
      updatedData[props.rowIndex].horaVenta =
        (bandaSalarialValue * convertirPorcentaje(cfteVenta)) / 160;
    } else if (selectedTipoProyecto.name === "Tarifa") {
      updatedData[props.rowIndex].sueldoBruto =
        perfilado * convertirPorcentaje(perfiladoPorcentaje) || 0;

      updatedData[props.rowIndex].subTotal =
        updatedData[props.rowIndex].perfilado * cfteVenta * cantidad;

      if (value > 0 && props.field === "sueldoBruto") {
        updatedData[props.rowIndex].sueldoBruto = value;
      }
    } else {
      updatedData[props.rowIndex].valorHoraCosto =
        (updatedData[props.rowIndex].sueldoBruto *
          updatedData[props.rowIndex].cantidad +
          updatedData[props.rowIndex].costoUnitario) /
        updatedData[props.rowIndex].cantidad /
        160;

      updatedData[props.rowIndex].subTotal =
        updatedData[props.rowIndex].sueldoBruto * cantidad * cfteVenta;

      if (updatedData[props.rowIndex]?.valorHoraCosto !== 0 && cfteVenta > 0) {
        updatedData[props.rowIndex].horaVenta =
          updatedData[props.rowIndex].valorHoraCosto * cfteVenta;
      }
      updatedData[props.rowIndex].subtotalCosto =
        updatedData[props.rowIndex].valorHoraCosto?.toFixed(2) *
        updatedData[props.rowIndex].cantHoras;
      updatedData[props.rowIndex].ventaTotal =
        updatedData[props.rowIndex].horaVenta *
        updatedData[props.rowIndex].cantHoras;
    }

    setData(updatedData);
  };

  const getRolesForBase = (baseName) => {
    const base = bases.find((b) => b.base.name === baseName);
    return base ? base.base.roles : [];
  };

  const getSenioritiesForRole = (baseName, roleName) => {
    const roles = getRolesForBase(baseName);
    const role = roles.find((r) => r.name === roleName);
    return role ? role.seniorities : [];
  };

  const getSkillsForSeniority = (baseName, roleName, seniorityName) => {
    const seniorities = getSenioritiesForRole(baseName, roleName);
    const seniority = seniorities.find((s) => s.name === seniorityName);
    return seniority ? seniority.skills : [];
  };

  const bandaSalarialAmounts = (baseName, roleName, seniorityName) => {
    const seniorities = getSenioritiesForRole(baseName, roleName);
    const selectedSeniority = seniorities.find((s) => s.name === seniorityName);

    if (!selectedSeniority) {
      return [];
    }
    return [
      {
        label: `${
          selectedSeniority.lowName
        }: $${selectedSeniority.lowAmount?.toLocaleString()}`,
        value: selectedSeniority.lowAmount,
      },
      {
        label: `${
          selectedSeniority.medName
        }: $${selectedSeniority.medAmount?.toLocaleString()}`,
        value: selectedSeniority.medAmount,
      },
      {
        label: `${
          selectedSeniority.highName
        }: $${selectedSeniority.highAmount?.toLocaleString()}`,
        value: selectedSeniority.highAmount,
      },
    ];
  };

  const renderDropdownBody = (rowData, field, options) => {
    return (
      <Dropdown
        value={rowData[field]}
        options={options.map((opt) => ({ label: opt, value: opt }))}
        onChange={(e) =>
          onEditorValueChange(
            { rowIndex: data.indexOf(rowData), field },
            e.value
          )
        }
        placeholder="Seleccionar"
      />
    );
  };

  const renderInputNumberBody = (
    rowData,
    field,
    isEditableColumn,
    disabled,
    useDecimals = true
  ) => {
    if (isEditableColumn) {
      return (
        <div style={{ position: "relative" }}>
          <InputNumber
            value={
              field === "valorHoraCosto"
                ? rowData["costoUnitario"] !== 0
                  ? rowData["valorHoraCosto"]
                  : 0
                : rowData[field]
            }
            onValueChange={(e) => {
              onEditorValueChange(
                { rowIndex: data.indexOf(rowData), field },
                e.value
              );
            }}
            placeholder="0"
            className="input-number-class"
            disabled={disabled}
            maxFractionDigits={useDecimals ? 2 : 0}
            mode="decimal"
            min={0}
          />
        </div>
      );
    } else {
      return (
        <InputNumber
          value={rowData[field]}
          onValueChange={(e) =>
            onEditorValueChange(
              { rowIndex: data.indexOf(rowData), field },
              e.value
            )
          }
          placeholder="0"
          disabled={disabled}
          maxFractionDigits={useDecimals ? 2 : 0}
          min={0}
        />
      );
    }
  };

  const renderInputNumberCfteBody = (
    rowData,
    field,
    isEditableColumn,
    disabled,
    useDecimals = true
  ) => {
    if (isEditableColumn) {
      return (
        <div style={{ position: "relative" }}>
          <InputNumber
            value={rowData[field] || null}
            onValueChange={(e) => {
              onEditorValueChange(
                { rowIndex: data.indexOf(rowData), field },
                e.value
              );
            }}
            placeholder="0.00"
            className="input-number-class"
            disabled={disabled}
            maxFractionDigits={useDecimals ? 2 : 0}
            minFractionDigits={useDecimals ? 2 : 0}
            maxLength={5}
            mode="decimal"
            min={0}
            onFocus={(e) => {
              if (rowData[field] === 0 || rowData[field] === null) {
                onEditorValueChange(
                  { rowIndex: data.indexOf(rowData), field },
                  null
                );
              }
            }}
          />
        </div>
      );
    } else {
      return (
        <InputNumber
          value={rowData[field]}
          onValueChange={(e) =>
            onEditorValueChange(
              { rowIndex: data.indexOf(rowData), field },
              e.value
            )
          }
          placeholder="0"
          disabled={disabled}
          maxFractionDigits={useDecimals ? 2 : 0}
          min={0}
        />
      );
    }
  };

  const renderInputNumberCostoBody = (
    rowData,
    field,
    isEditableColumn,
    disabled,
    useDecimals = true
  ) => {
    if (isEditableColumn) {
      return (
        <div style={{ position: "relative" }}>
          <InputNumber
            value={rowData[field]}
            onValueChange={(e) => {
              onEditorValueChange(
                { rowIndex: data.indexOf(rowData), field },
                e.value
              );
            }}
            placeholder="0"
            className="input-number-class"
            disabled={disabled}
            maxFractionDigits={useDecimals ? 2 : 0}
            suffix={field === "cfteVenta" ? "%" : ""}
            mode="decimal"
          />
          {renderPencilButton(rowData)}
        </div>
      );
    } else {
      return (
        <InputNumber
          value={rowData[field]}
          onValueChange={(e) =>
            onEditorValueChange(
              { rowIndex: data.indexOf(rowData), field },
              e.value
            )
          }
          placeholder="0"
          disabled={disabled}
          maxFractionDigits={useDecimals ? 2 : 0}
        />
      );
    }
  };

  // const renderInputTextEditor = (props) => {
  //   return (
  //     <InputText
  //       value={props.rowData[props.field]}
  //       onChange={(e) => onEditorValueChange(props, e.target.value)}
  //     />
  //   );
  // };

  //  -----funcionalidad de seleccionar todo-----
  // const toggleSelectAll = () => {
  //     setSelectAll(!selectAll);
  //     const selected = selectAll ? [] : [...data];
  //     setSelectedResources(selected);
  // };

  // ------funcionalidad de los checkboxes-----
  // const renderCheckbox = (rowData) => {
  //     return (
  //         <Checkbox
  //             checked={selectedResources.includes(rowData)}
  //             onChange={(e) => {
  //                 let selected = [...selectedResources];
  //                 if (e.checked)
  //                     selected.push(rowData);
  //                 else
  //                     selected = selected.filter(r => r.id !== rowData.id);
  //                 setSelectedResources(selected);
  //             }}
  //         />
  //     );
  // };

  const renderDeleteButton = (rowData) => {
    return (
      <>
        <div
          onClick={data.length > 0 ? () => deleteResource(rowData) : null}
          className={`${
            data.length > 0 ? "trash-button-container" : "trash-desable"
          }`}
        >
          <img src={TrashButton} alt="Eliminar" />
        </div>
      </>
    );
  };

  const dataTableChecked = () => {
    const getRequiredFields = (tipoProyecto) => {
      switch (tipoProyecto) {
        case "Contractor":
          return [
            "base",
            "rol",
            "seniority",
            "skill",
            "cantidad",
            "tipoContratacion",
            "cfteVenta",
            "subTotal",
            "horaVenta",
            "costoUnitario",
          ];
        case "Tarifa":
          return [
            "base",
            "rol",
            "seniority",
            "skill",
            "cantidad",
            "perfilado",
            "perfiladoPorcentaje",
            "sueldoBruto",
            "tipoContratacion",
            "cfteVenta",
            "subTotal",
            "costoUnitario",
          ];
        default:
          return [
            "base",
            "rol",
            "seniority",
            "skill",
            "cantidad",
            "sueldoBruto",
            "tipoContratacion",
            "cfteVenta",
            "subTotal",
            "horaVenta",
            "costoUnitario",
            "cantHoras",
          ];
      }
    };

    const requiredFields = getRequiredFields(selectedTipoProyecto.name);

    const dataOnTable = data.every((row) =>
      requiredFields.every(
        (field) => row[field] !== null && row[field] !== "" && row[field] !== 0
      )
    );

    if (data.length > 0 && dataOnTable) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };
  const renderPencilButton = (rowData) => {
    return (
      <div style={{ position: "relative" }}>
        <span style={{ position: "absolute", right: "-6px", top: "-27px" }}>
          <i
            className="pi pi-pencil"
            aria-hidden="true"
            style={{
              cursor: "pointer",
              fontSize: "15px",
              color:
                rowData?.tipoContratacion !== "" &&
                rowData?.cantidad !== 0 &&
                rowData?.sueldoBruto !== 0
                  ? "rgba(4, 74, 205, 1)"
                  : "gray",
            }}
            onClick={() => {
              if (
                rowData.tipoContratacion !== "" &&
                rowData.cantidad !== 0 &&
                rowData.sueldoBruto !== 0
              ) {
                setOpenModalCostos(true);
                setFilaSeleccionada(rowData);
              }
            }}
          ></i>
        </span>
      </div>
    );
  };

  useEffect(() => {
    dataTableChecked();
    setDataTablaTalento(data);
  }, [data]);

  return (
    <>
      <div className="talento-Container">
        <div className="head-container">
          <div className="title-container">
            <p className="title-talento">
              Proyecto {datosProyectos?.nombreOportunidad}
            </p>
            <p className="title-talento">
              |
              <CalendarMonthIcon
                style={{
                  padding: "2px",
                  marginLeft: "10px",
                  marginRight: "5px",
                }}
              />{" "}
              Duracion: {duracionDelProyecto?.name}
              {horasVentaTalento && (
                <>
                  <AccessTimeIcon
                    style={{
                      padding: "2px",
                      marginLeft: "10px",
                      marginRight: "5px",
                    }}
                  />
                  {horasVentaTalento.toLocaleString("es-ES", {
                    useGrouping: true,
                  })}{" "}
                  hs.
                </>
              )}
            </p>
            <p className="title-talento">
              {datosProyectos.moneda === "USD" && (
                <>
                  | USD venta
                  <span
                    style={{
                      marginLeft: "3px",
                      marginRight: "3px",
                      color: "rgb(0 0 0 / 75%)",
                    }}
                  >
                    {tipoMoneda.find((m) => m.value === "USD")?.price}
                  </span>
                </>
              )}
              {datosProyectos.moneda === "EUR" && (
                <>
                  | EUR venta
                  <span
                    style={{ marginLeft: "3px", color: "rgb(0 0 0 / 75%)" }}
                  >
                    {tipoMoneda.find((m) => m.value === "EUR")?.price}
                  </span>
                </>
              )}
            </p>
          </div>
          <div className="bottons-section">
            {/* ---boton icono trash ara eliminar filas seleccionadas---
                    <div
                        onClick={data.length > 0 ? deleteSelectedResources : null}
                        className={`${data.length > 0 ? 'trash-button-container' : 'trash-desable'}`}
                    >
                        <img src={TrashButton} alt="Eliminar" />
                    </div> */}
            <Button className="add-item" onClick={addResource}>
              <i
                className="pi pi-plus"
                id="plus-continuar-seleecion-talento"
              ></i>
              Agregar talento
            </Button>
          </div>
        </div>
        <div className="table-scrollable-container custom-datatable">
          <DataTable
            value={data}
            editable="true"
            onRowEditChange={onRowEditChange}
            rowHover
            className="p-datatable-gridlines"
            style={{ background: "rgba(244, 247, 252, 0.75)" }}
          >
            {/* ------checkboxes para cada fila que se crea en la tabla------
                <Column header={<Checkbox onChange={toggleSelectAll} checked={selectAll} />} body={renderCheckbox} headerClassName="table-header" /> */}
            <Column
              field="base"
              header="Base"
              body={(rowData) =>
                renderDropdownBody(
                  rowData,
                  "base",
                  bases.map((base) => base.base.name)
                )
              }
              headerClassName="table-header"
              style={{ border: "none", textAlign: "start" }}
            />
            <Column
              field="rol"
              header="Rol"
              body={(rowData) => {
                const roles = getRolesForBase(rowData.base)
                  .map((role) => role.name)
                  .filter(
                    (name) => typeof name === "string" && name.trim() !== ""
                  );

                return renderDropdownBody(rowData, "rol", roles);
              }}
              headerClassName="table-header"
              style={{ border: "none", textAlign: "start" }}
            />
            <Column
              field="seniority"
              header="Seniority"
              body={(rowData) => {
                const seniorities = getSenioritiesForRole(
                  rowData.base,
                  rowData.rol
                )
                  .map((s) => s.name)
                  .filter(
                    (name) => typeof name === "string" && name.trim() !== ""
                  );

                return renderDropdownBody(rowData, "seniority", seniorities);
              }}
              headerClassName="table-header"
              style={{ border: "none", textAlign: "start" }}
            />
            <Column
              field="skill"
              header="Skill"
              body={(rowData) => {
                const skills = getSkillsForSeniority(
                  rowData.base,
                  rowData.rol,
                  rowData.seniority
                ).filter((skill) => (skill ? skill.trim() !== "" : false));
                return renderDropdownBody(rowData, "skill", skills);
              }}
              headerClassName="table-header"
              style={{ border: "none", textAlign: "start" }}
            />

            <Column
              field="cantidad"
              header="Cantidad"
              body={(rowData) =>
                renderInputNumberBody(rowData, "cantidad", false, false)
              }
              headerClassName="table-header"
              style={{ border: "none", textAlign: "start" }}
            />
            {selectedTipoProyecto.name === "Tarifa" && (
              <Column
                field="perfilado"
                header="Perfilado"
                body={(rowData) =>
                  renderInputNumberBody(rowData, "perfilado", true)
                }
                headerClassName="table-header"
                style={{ border: "none", textAlign: "start" }}
              />
            )}
            {selectedTipoProyecto.name === "Tarifa" && (
              <Column
                field="perfiladoPorcentaje"
                header="% Perfilado"
                body={(rowData) =>
                  renderDropdownBody(
                    rowData,
                    "perfiladoPorcentaje",
                    perfiladoProcentaje
                  )
                }
                headerClassName="table-header"
                style={{ border: "none", textAlign: "start" }}
              />
            )}
            {selectedTipoProyecto.name !== "Tarifa" && (
              <Column
                field="bandaSalarial"
                header="Banda Salarial"
                body={(rowData) => {
                  const options = bandaSalarialAmounts(
                    rowData.base,
                    rowData.rol,
                    rowData.seniority
                  );

                  return renderDropdownBody(
                    rowData,
                    "bandaSalarial",
                    options.map((option) => option.label)
                  );
                }}
                headerClassName="table-header"
                style={{ border: "none", textAlign: "start" }}
              />
            )}

            <Column
              field="sueldoBruto"
              header="Sueldo Bruto"
              body={(rowData) =>
                renderInputNumberBody(rowData, "sueldoBruto", true)
              }
              headerClassName="table-header"
              style={{ border: "none", textAlign: "start" }}
            />

            <Column
              field="tipoContratacion"
              header="Tipo de Contratación"
              body={(rowData) =>
                renderDropdownBody(
                  rowData,
                  "tipoContratacion",
                  tiposContratacion
                )
              }
              headerClassName="table-header"
              style={{ border: "none", textAlign: "start" }}
            />
            <Column
              field="costoUnitario"
              header="Costo"
              body={(rowData) =>
                renderInputNumberCostoBody(rowData, "costoUnitario", true, true)
              }
              headerClassName="table-header"
              style={{ minWidth: "5rem", border: "none", textAlign: "start" }}
            />

            {(selectedTipoProyecto.name === "Llave en mano" ||
              selectedTipoProyecto.name === "Servicios") && (
              <Column
                field="valorHoraCosto"
                header="Valor hora costo"
                body={(rowData) =>
                  renderInputNumberBody(rowData, "valorHoraCosto", true, true)
                }
                headerClassName="table-header"
                style={{ minWidth: "5rem", border: "none", textAlign: "start" }}
              />
            )}

            {(selectedTipoProyecto.name === "Llave en mano" ||
              selectedTipoProyecto.name === "Servicios") && (
              <Column
                field="cantHoras"
                header="Cant. Horas"
                body={(rowData) =>
                  renderInputNumberBody(rowData, "cantHoras", true)
                }
                headerClassName="table-header"
                style={{ minWidth: "5rem", border: "none", textAlign: "start" }}
              />
            )}

            {(selectedTipoProyecto.name === "Llave en mano" ||
              selectedTipoProyecto.name === "Servicios") && (
              <Column
                field="subtotalCosto"
                header="Subtotal Costo"
                body={(rowData) =>
                  renderInputNumberBody(rowData, "subtotalCosto", true, true)
                }
                headerClassName="table-header"
                style={{ minWidth: "5rem", border: "none", textAlign: "start" }}
              />
            )}

            <Column
              field="cfteVenta"
              header="Cfte. Venta"
              body={(rowData) =>
                renderInputNumberCfteBody(rowData, "cfteVenta", true)
              }
              headerClassName="table-header"
              style={{ border: "none", textAlign: "start" }}
            />

            {(selectedTipoProyecto.name === "Llave en mano" ||
              selectedTipoProyecto.name === "Servicios") && (
              <Column
                field="horaVenta"
                header="Valor hora Venta"
                body={(rowData) =>
                  renderInputNumberBody(rowData, "horaVenta", true)
                }
                headerClassName="table-header"
                style={{ border: "none", textAlign: "start" }}
              />
            )}

            {(selectedTipoProyecto.name === "Llave en mano" ||
              selectedTipoProyecto.name === "Servicios") && (
              <Column
                field="ventaTotal"
                header="Venta total"
                body={(rowData) =>
                  renderInputNumberBody(rowData, "ventaTotal", true)
                }
                headerClassName="table-header"
                style={{ minWidth: "5rem", border: "none", textAlign: "start" }}
              />
            )}

            {(selectedTipoProyecto.name === "Contractor" ||
              selectedTipoProyecto.name === "Tarifa") && (
              <Column
                field="subTotal"
                header="SubTotal Venta"
                body={(rowData) =>
                  renderInputNumberBody(rowData, "subTotal", true)
                }
                headerClassName="table-header"
                style={{ border: "none", textAlign: "start" }}
              />
            )}

            <Column
              body={renderDeleteButton}
              headerClassName="table-header"
              style={{ border: "none" }}
            />
          </DataTable>
        </div>
        {openModalCostos && (
          <ModalCostoUnitario
            cancel={setOpenModalCostos}
            data={filaSeleccionada}
            calculo={setData}
            datos={data}
            variables={variables}
          />
        )}
      </div>
      {openModal && (
        <ModalTotalCostos open={setOpenModal} variables={variables} />
      )}
      <CalculoTalento
        totalHorasTalento={totalHorasTalento}
        selectedTipoProyecto={selectedTipoProyecto}
        data={data}
        open={setOpenModal}
        isButtonDisabled={isButtonDisabled}
      />
    </>
  );
};

export default TablaSeleccionTalento;
