import { createContext, useContext, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { AuthContext } from "./AuthContext";
import { EXCEL_TYPES } from "../pages/Informes/types";

export const NovedadesContext = createContext();

const NovedadesContextProvider = ({ children }) => {
  const [novedades, setNovedades] = useState([]);
  const [nuevaNovedad, setNuevaNovedad] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [tipoNovedad, setTipoNovedad] = useState(0);
  const [proveedores, setProveedores] = useState([]);
  const [recursosArray, setRecursosArray] = useState([]);
  const [usuariosExternos, setUsuariosExternos] = useState([]);
  const [recursosSameManager, setRecursosSameManager] = useState([]);
  const [recursosFilteredManager, setRecursosFilteredManager] = useState([]);
  const [recursosFromCeco, setRecursosFromCeco] = useState([]);
  const [periodos, setPeriodos] = useState([]);
  const [cambioNovedad, setCambioNovedad] = useState(false);
  const [cecos, setCecos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [vacaciones, setVacaciones] = useState();
  const [patagonianDay, setPatagonianDay] = useState();
  const { setLoadGoogle, getTokenFromSessionStorage, setUsuario, usuario } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const toast = useRef(null);

  const apiUrl = process.env.REACT_APP_API_URL;
  const key = process.env.REACT_APP_TOKEN_KEY;

  const getNovedades = async (...filter) => {
    let textFilter = filter.length > 0 ? "/?" + filter.join("&") : "";
    setLoading(true);
    try {
      const token = getTokenFromSessionStorage(key);
      const response = await fetch(apiUrl + "/Novedad" + textFilter, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        const data = await response.json();
        if (!data) {
          return [];
        }
        return data;
      }
    } catch (err) {
      console.log("error", err);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const getNovedadesPendientes = async (responsable = "grupo") => {
    setLoading(true);
    try {
      const token = getTokenFromSessionStorage(key);
      const response = await fetch(
        apiUrl + `/Novedad/?estadoWF=pendiente&responsableWF=${responsable}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        const data = await response.json();
        if (!data) {
          return [];
        }
        return data;
      }
    } catch (err) {
      console.log("error", err);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const getNovedadesAYR = async (responsable = "grupo") => {
    setLoading(true);
    try {
      const token = getTokenFromSessionStorage(key);
      const response = await fetch(
        apiUrl +
          `/Novedad/?estadoWF=aceptada|rechazada&responsableWF=${responsable}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        const data = await response.json();
        if (!data) {
          return [];
        }
        return data;
      }
    } catch (err) {
      console.log("error", err);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const getProveedores = () => {
    const token = getTokenFromSessionStorage(key);
    fetch(apiUrl + "/Proveedor", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Expiró la sesión, vuelva a ingresar",
            life: 5000,
          });
          localStorage.clear();
          setUsuario(null);
          setLoadGoogle(false);
          navigate("/login", { replace: true });
          return;
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setProveedores(data);
      })
      .catch((err) => console.log("error", err));
  };

  const getCecos = () => {
    const token = getTokenFromSessionStorage(key);
    fetch(apiUrl + "/Cecos", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Expiró la sesión, vuelva a ingresar",
            life: 5000,
          });
          localStorage.clear();
          setUsuario(null);
          setLoadGoogle(false);
          navigate("/login", { replace: true });
          return;
        } else {
          return response.json();
        }
      })
      .then((data) => {
        let arrayCecosCuit = data.map((item) => {
          let Resumen = `${item.Cliente} - ${item.CuitCuil}`;
          return {
            ...item,
            Resumen,
          };
        });
        setCecos(arrayCecosCuit);
      })
      .catch((err) => console.log("error", err));
  };

  const getClientes = () => {
    const token = getTokenFromSessionStorage(key);
    fetch(apiUrl + "/Cecos", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Expiró la sesión, vuelva a ingresar",
            life: 5000,
          });
          localStorage.clear();
          setUsuario(null);
          setLoadGoogle(false);
          navigate("/login", { replace: true });
          return;
        } else {
          return response.json();
        }
      })
      .then((data) => {
        let arrayCecos = data.filter((item) => item.Codigo > 99);
        let arrayCecosCuit = arrayCecos.map((item) => {
          let Resumen = `${item.Cliente} - ${item.CuitCuil}`;
          return {
            ...item,
            Resumen,
          };
        });
        let arrayClientes = arrayCecosCuit.filter((elemento, index) => {
          return (
            arrayCecosCuit.findIndex(
              (e, i) => i !== index && e.Cliente === elemento.Cliente
            ) === -1
          );
        });
        setClientes(arrayClientes);
      })

      .catch((err) => console.log("error", err));
  };

  const addNovedad = (campos, adjuntos = []) => {
    const token = getTokenFromSessionStorage(key);
    const formData = new FormData();
    for (const prop in campos) {
      formData.append(prop, campos[prop]);
    }
    for (let ind = 0; ind < adjuntos.length; ind++) {
      formData.append("AdjuntosBin", adjuntos[ind]);
    }
    fetch(apiUrl + "/Novedad", {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    })
      .then((response) => {
        if (response.status === 401) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Expiró la sesión, vuelva a ingresar",
            life: 5000,
          });
          localStorage.clear();
          setUsuario(null);
          setLoadGoogle(false);
          navigate("/login", { replace: true });
          return;
        } else if (response.status === 200) {
          toast.current.show({
            severity: "success",
            summary: "Confirmación",
            detail: "Novedad agregada con éxito.",
            life: 5000,
          });
          setCambioNovedad(!cambioNovedad);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error de servicio " + response.status,
            detail: response.statusText,
            life: 5000,
          });
        }
      })
      .catch((e) => {
        console.log("Error de conexión de Red", e);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error de conexión de red",
          life: 10000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return;
      });
  };
  const agregaAdjuntos = async (novedadId, adjuntos) => {
    const token = getTokenFromSessionStorage(key);
    const formData = new FormData();
    for (let ind = 0; ind < adjuntos.length; ind++) {
      formData.append("archivo", adjuntos[ind]);
    }
    await fetch(apiUrl + "/Archivos/Novedad/Adjuntos/" + novedadId, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    }).then((response) => {
      if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return;
      } else if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "Novedad agregada con éxito.",
          life: 5000,
        });
        setCambioNovedad(!cambioNovedad);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error de servicio " + response.status,
          detail: response.statusText,
          life: 5000,
        });
      }
    });
  };

  const updateMotivo = (id, body) => {
    const token = getTokenFromSessionStorage(key);
    fetch(apiUrl + "/Novedad/" + id, {
      method: "PATCH",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    })
      .then(() => setCambioNovedad(!cambioNovedad))
      .catch((err) => console.log("error", err));
  };

  const getRecursosSameManager = () => {
    const token = getTokenFromSessionStorage(key);
    fetch(apiUrl + "/RecursoFiltered", {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Expiró la sesión, vuelva a ingresar",
            life: 5000,
          });
          localStorage.clear();
          setUsuario(null);
          setLoadGoogle(false);
          navigate("/login", { replace: true });
          return;
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setRecursosSameManager(data ? data : []);
      })
      .catch((err) => console.log("error", err));
  };

  const getRecursosFilteredManager = () => {
    const token = getTokenFromSessionStorage(key);
    fetch(apiUrl + "/RecursoFiltered/Manager", {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Expiró la sesión, vuelva a ingresar",
            life: 5000,
          });
          localStorage.clear();
          setUsuario(null);
          setLoadGoogle(false);
          navigate("/login", { replace: true });
          return;
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setRecursosFilteredManager(data ? data : []);
      })
      .catch((err) => console.log("error", err));
  };

  const getRecursosFromCeco = (idCeco) => {
    const token = getTokenFromSessionStorage(key);
    fetch(apiUrl + "/Recurso/?cecos=" + idCeco, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => setRecursosFromCeco(data))
      .catch((err) => console.log("error", err));
  };

  const getRecursos = () => {
    const token = getTokenFromSessionStorage(key);
    fetch(apiUrl + "/Recurso", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Expiró la sesión, vuelva a ingresar",
            life: 5000,
          });
          localStorage.clear();
          setUsuario(null);
          setLoadGoogle(false);
          navigate("/login", { replace: true });
          return;
        } else {
          return response.json();
        }
      })
      .then((data) => {
        let arrayRRHH = data.map((item) => {
          let ccPorcentaje = "";
          let ccNumero = "";
          let ccNombre = "";
          item.Rcc?.map((rcc, index) => {
            if (index + 1 === item.Rcc?.length) {
              ccPorcentaje += rcc.CcPorc;
              ccNumero += rcc.CcNum;
              ccNombre += rcc.CcNombre;
              return;
            }
            ccPorcentaje += rcc.CcPorc + " - ";
            ccNumero += rcc.CcNum + " - ";
            ccNombre += rcc.CcNombre + " - ";
          });
          let Resumen = `${item.Apellido}, ${item.Nombre} - (${item.Legajo})`;
          let datoGerente = `(${item.Legajo}) - ${item.Apellido}, ${item.Nombre}`;
          return {
            ...item,
            Resumen,
            datoGerente,
            ccPorcentaje,
            ccNumero,
            ccNombre,
          };
        });
        setRecursosArray(arrayRRHH);
      })
      .catch((err) => console.log("error", err));
  };

  const getUsuariosExternos = () => {
    const token = getTokenFromSessionStorage(key);
    fetch(apiUrl + "/Recurso", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Expiró la sesión, vuelva a ingresar",
            life: 5000,
          });
          localStorage.clear();
          setUsuario(null);
          setLoadGoogle(false);
          navigate("/login", { replace: true });
          return;
        } else {
          return response.json();
        }
      })
      .then((data) => {
        let filtrados = data.filter((item) => item.Legajo > 10000);
        let arrayRRHH = filtrados.map((item) => {
          let ccNumero = "";
          let ccNombre = "";
          item.Rcc?.map((rcc, index) => {
            if (index + 1 === item.Rcc?.length) {
              ccNumero += rcc.CcNum;
              ccNombre += rcc.CcNombre;
              return;
            }
            ccNumero += rcc.CcNum + " - ";
            ccNombre += rcc.CcNombre + " - ";
          });
          let Resumen = `${item.Apellido}, ${item.Nombre} - (${item.Legajo})`;
          let datoGerente = `(${item.Legajo}) - ${item.Apellido}, ${item.Nombre}`;
          return {
            ...item,
            Resumen,
            datoGerente,
            ccNumero,
            ccNombre,
          };
        });
        setUsuariosExternos(arrayRRHH);
      })
      .catch((err) => console.log("error", err));
  };

  const getFreelance = async () => {
    setLoading(true);
    try {
      const token = getTokenFromSessionStorage(key);
      const response = await fetch(apiUrl + "/Freelance", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        const data = await response.json();
        if (!data) {
          return [];
        }
        return data;
      }
    } catch (err) {
      console.log("error", err);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const getSelfRecurso = () => {
    const token = getTokenFromSessionStorage(key);
    fetch(apiUrl + "/Recurso/" + usuario.IdSecuencial, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Expiró la sesión, vuelva a ingresar",
            life: 5000,
          });
          localStorage.clear();
          setUsuario(null);
          setLoadGoogle(false);
          navigate("/login", { replace: true });
          return;
        } else {
          return response.json();
        }
      })
      .then((data) => {
        const total = data.Vacaciones
          ? data.Vacaciones.reduce((total, e) => total + e.CantidadComun, 0)
          : 0;
        setVacaciones(total);
        setPatagonianDay(
          data.Vacaciones[data.Vacaciones?.length - 1]?.CantidadPatagonian
        );
      })
      .catch((err) => console.log("error", err));
  };

  const insertRecurso = (body) => {
    const token = getTokenFromSessionStorage(key);
    fetch(apiUrl + "/Recurso", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          toast.current.show({
            severity: "success",
            summary: "Confirmación",
            detail: "Registro agregado con éxito.",
            life: 5000,
          });
          setCambioNovedad(!cambioNovedad);
        } else {
          console.log("entro al else");
          throw response;
        }
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Error " + err.status,
          detail: err.statusText,
          life: 5000,
        });
      });
  };

  const insertFreelance = (body) => {
    const token = getTokenFromSessionStorage(key);
    fetch(apiUrl + "/Freelance", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          toast.current.show({
            severity: "success",
            summary: "Confirmación",
            detail: "Registro agregado con éxito.",
            life: 5000,
          });
          setCambioNovedad(!cambioNovedad);
        } else {
          console.log("entro al else");
          throw response;
        }
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Error " + err.status,
          detail: err.statusText,
          life: 5000,
        });
      });
  };

  const updateFreelance = (body) => {
    const token = getTokenFromSessionStorage(key);
    fetch(apiUrl + "/Freelance", {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          toast.current.show({
            severity: "success",
            summary: "Confirmación",
            detail: "Registro actualizado con éxito.",
            life: 5000,
          });
          setCambioNovedad(!cambioNovedad);
        } else {
          console.log("entro al else");
          throw response;
        }
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Error " + err.status,
          detail: err.statusText,
          life: 5000,
        });
      });
  };

  const updateRecurso = (body) => {
    const token = getTokenFromSessionStorage(key);
    fetch(apiUrl + "/Recurso", {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          toast.current.show({
            severity: "success",
            summary: "Confirmación",
            detail: "Registro actualizado con éxito.",
            life: 5000,
          });
          setCambioNovedad(!cambioNovedad);
        } else {
          console.log("entro al else");
          throw response;
        }
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Error " + err.status,
          detail: err.statusText,
          life: 5000,
        });
      });
  };

  const updateVacaciones = (id, body) => {
    const token = getTokenFromSessionStorage(key);
    fetch(apiUrl + "/Vacaciones/" + id, {
      method: "PATCH",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          toast.current.show({
            severity: "success",
            summary: "Confirmación",
            detail: "Registro actualizado con éxito.",
            life: 5000,
          });
          setCambioNovedad(!cambioNovedad);
        } else {
          throw response;
        }
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Error " + err.status,
          detail: err.statusText,
          life: 5000,
        });
      });
  };

  const deleteRecurso = (id) => {
    const token = getTokenFromSessionStorage(key);
    fetch(apiUrl + "/Recurso/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          toast.current.show({
            severity: "success",
            summary: "Confirmación",
            detail: "Registro eliminado con éxito.",
            life: 5000,
          });
          setCambioNovedad(!cambioNovedad);
        } else {
          console.log("entro al else");
          throw response;
        }
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Error " + err.status,
          detail: err.statusText,
          life: 5000,
        });
      });
  };

  const getPeriodos = () => {
    const token = getTokenFromSessionStorage(key);
    fetch(apiUrl + "/Periodos", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Expiró la sesión, vuelva a ingresar",
            life: 5000,
          });
          localStorage.clear();
          setUsuario(null);
          setLoadGoogle(false);
          navigate("/login", { replace: true });
          return;
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setPeriodos(data);
      })
      .catch((err) => console.log("error", err));
  };

  const getExcel = async (tipo, fechas) => {
    let endpoint;
    fechas = fechas ? fechas : "";
    if (tipo === EXCEL_TYPES.clientes) {
      endpoint = "/Excel/Admin";
    }
    if (tipo === EXCEL_TYPES.proveedores) {
      endpoint = "/Excel/PagoProveedores";
    }
    if (tipo === EXCEL_TYPES.po) {
      endpoint = "/Excel/Novedad";
    }
    if (tipo === EXCEL_TYPES.recursos) {
      endpoint = "/Excel/Recursos";
    }
    try {
      setLoadingExcel(true);
      const token = getTokenFromSessionStorage(key);
      const respuesta = await fetch(apiUrl + endpoint + fechas, {
        method: "GET",
        mode: "cors",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (respuesta.status === 200) {
        const blob = await respuesta.blob();
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "Excel descargado con éxito.",
          life: 5000,
        });
        setLoadingExcel(false);
        return blob;
      } else {
        console.error("Error: ", respuesta.status, respuesta.statusText);
        setLoadingExcel(false);
        return null;
      }
    } catch (error) {
      console.error("Error al descargar los archivos", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error al descargar los archivos",
        life: 5000,
      });
      setLoadingExcel(false);
      return null;
    }
  };

  const getExcelFreelance = async () => {
    try {
      setLoadingExcel(true);
      const token = getTokenFromSessionStorage(key);
      const respuesta = await fetch(apiUrl + "/Excel/Freelance/", {
        method: "GET",
        mode: "cors",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (respuesta.status === 200) {
        const blob = await respuesta.blob();
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "Excel descargado con éxito.",
          life: 5000,
        });
        setLoadingExcel(false);
        return blob;
      } else {
        console.error("Error: ", respuesta.status, respuesta.statusText);
        setLoadingExcel(false);
        return null;
      }
    } catch (error) {
      console.error("Error al descargar los archivos", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error al descargar los archivos",
        life: 5000,
      });
      setLoadingExcel(false);
      return null;
    }
  };

  const getRecursosReport = async () => {
    try {
      setLoadingExcel(true);
      const token = getTokenFromSessionStorage(key);
      const respuesta = await fetch(apiUrl + "/reports/excel/recursos", {
        method: "GET",
        mode: "cors",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (respuesta.status === 200) {
        const blob = await respuesta.blob();
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "Reporte descargado con éxito.",
          life: 5000,
        });
        setLoadingExcel(false);
        return blob;
      } else {
        console.error("Error: ", respuesta.status, respuesta.statusText);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error al descargar el archivo",
          life: 5000,
        });
        setLoadingExcel(false);
        return null;
      }
    } catch (error) {
      console.error("Error al descargar el archivo", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error al descargar el archivo",
        life: 5000,
      });
      setLoadingExcel(false);
      return null;
    }
  };

  const getCecosReport = async () => {
    try {
      setLoadingExcel(true);
      const token = getTokenFromSessionStorage(key);
      const respuesta = await fetch(apiUrl + "/reports/excel/cecos", {
        method: "GET",
        mode: "cors",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (respuesta.status === 200) {
        const blob = await respuesta.blob();
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "Reporte descargado con éxito.",
          life: 5000,
        });
        setLoadingExcel(false);
        return blob;
      } else {
        console.error("Error: ", respuesta.status, respuesta.statusText);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error al descargar el archivo",
          life: 5000,
        });
        setLoadingExcel(false);
        return null;
      }
    } catch (error) {
      console.error("Error al descargar el archivo", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error al descargar el archivo",
        life: 5000,
      });
      setLoadingExcel(false);
      return null;
    }
  };

  const updateEstado = (estado, id) => {
    const token = getTokenFromSessionStorage(key);
    fetch(apiUrl + "/" + estado + "/Novedad/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Expiró la sesión, vuelva a ingresar",
            life: 5000,
          });
          localStorage.clear();
          setUsuario(null);
          setLoadGoogle(false);
          navigate("/login", { replace: true });
          return;
        } else {
          setCambioNovedad(!cambioNovedad);
          return response.json();
        }
      })
      .catch((err) => console.log("error", err));
  };

  const getHistorialFreelance = async () => {
    // setLoading(true);
    try {
      const token = getTokenFromSessionStorage(key);
      const response = await fetch(apiUrl + "/Historial/Freelance", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        const data = await response.json();
        if (!data) {
          return [];
        }
        setCambioNovedad(!cambioNovedad);
        return data.filter((e) => e.Tipo !== "update");
      }
    } catch (err) {
      console.log("error", err);
      return [];
    } finally {
      //setLoading(false);
    }
  };

  return (
    <NovedadesContext.Provider
      value={{
        addNovedad,
        novedades,
        setNovedades,
        setNuevaNovedad,
        nuevaNovedad,
        tipoNovedad,
        setTipoNovedad,
        getNovedades,
        loading,
        setLoading,
        proveedores,
        getProveedores,
        recursosArray,
        getRecursos,
        getUsuariosExternos,
        usuariosExternos,
        getFreelance,
        getHistorialFreelance,
        getSelfRecurso,
        insertRecurso,
        insertFreelance,
        updateRecurso,
        updateFreelance,
        deleteRecurso,
        getRecursosSameManager,
        getRecursosFilteredManager,
        recursosSameManager,
        recursosFilteredManager,
        getRecursosFromCeco,
        recursosFromCeco,
        periodos,
        getPeriodos,
        updateEstado,
        updateVacaciones,
        cambioNovedad,
        cecos,
        getCecos,
        getClientes,
        clientes,
        getNovedadesPendientes,
        updateMotivo,
        getNovedadesAYR,
        agregaAdjuntos,
        getExcel,
        getExcelFreelance,
        getRecursosReport,
        getCecosReport,
        loadingExcel,
        vacaciones,
        patagonianDay,
      }}
    >
      {children}
      <Toast ref={toast} />
    </NovedadesContext.Provider>
  );
};

export default NovedadesContextProvider;
