import React, { useContext, useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { CotizadorContext } from "../../../contexts/CotizadorContext";
import "./ModalCostoUnitario.css";

const ModalCostoUnitario = ({
  position,
  cancel,
  data,
  calculo,
  datos,
  variables,
}) => {
  const {
    beneficios,
    getBeneficios,
    vacaciones,
    getVacaciones,
    datosProyectos,
    getCostosFijos,
    montosCostosFijos,
  } = useContext(CotizadorContext);

  useEffect(() => {
    getBeneficios();
    getVacaciones();
    getCostosFijos();
  }, []);

  const onHide = (name) => {
    cancel(false);
  };
  const [paqueteBeneficios, setPaqueteBeneficios] = useState(
    data.paqueteBeneficios
  );
  const [diasVacaciones, setDiasVacaciones] = useState(
    data.tipoContratacion === "Freelance" ? "" : data.diasVacaciones
  );
  const [variableCosts, setVariableCosts] = useState(data.costosVariables);
  const [fixedCosts, setFixedCosts] = useState(data.costosFijos);

  useEffect(() => {
    (paqueteBeneficios === "" || !paqueteBeneficios) &&
      setPaqueteBeneficios(
        beneficios.find((beneficio) => beneficio.name === "Paquete Base")
      );
  }, [beneficios]);

  useEffect(() => {
    if (diasVacaciones !== "" || data.tipoContratacion === "Freelance") {
      calculoCostosVariables();
    }
  }, [diasVacaciones, paqueteBeneficios]);

  const calculoCostosVariables = () => {
    //contribucion variable
    const sueldoBase =
      data.sueldoBruto === 0
        ? parseFloat(
            data.bandaSalarial
              ?.split(":")[1]
              .replace(/[^\d,]/g, "")
              .replace(",", ".")
          ) || 0
        : data.sueldoBruto;
    let plusVacacional = 0;
    const plusVacacional14 = variables?.find(
      (item) =>
        item.item === "Plus Vacacional dias:" && item.calculation === "14"
    );
    const plusVacacional21 = variables.find(
      (item) =>
        item.item === "Plus Vacacional dias:" && item.calculation === "21"
    );
    let paquete = (sueldoBase * paqueteBeneficios?.percent) / 100;
    let sac = 0;
    const SACPercent = variables.find(
      (item) => item.item === "SAC (s/ sueldo + plus vac.)"
    );
    //let obraSocial = 0;
    let patronales = 0;
    let detraccion = variables.find((item) => item.item === "Detracción");
    let asigFamiliares = 0;
    let inssjp = 0;
    const inssjpPercent = variables.find(
      (item) => item.item === "INSSJP  (s/sueldo - detracc + plus vac + sac)"
    );
    let fondoDesempleo = 0;
    let tasasLec = 0;
    let art = 0;
    const artPercent = variables.find(
      (item) => item.item === "ART (s/sueldo + plus vac + sac)"
    );
    let calculoContCariables = 0;

    //previsiones
    let pervIndemnizacionDespido = 0;
    const pervIndemnizacionDespidoPercent = variables.find(
      (item) => item.item === "Previsión Indemnización Despido"
    );
    let prepagas = 0;
    const prepagasPercent = variables.find((item) => item.item === "Prepagas");
    let bonoPerm = 0;
    const bonoPermPercent = variables.find((item) => item.item === "Bono Perm");
    let internet = 0;
    const internetPercent = variables.find((item) => item.item === "Internet");
    let guarderia = 0;
    let capacitacion = 0;
    let obraSocial = 0;
    const obraSocialPercent = variables.find(
      (item) =>
        item.item === "Obra Social  (s/sueldo - detracc + plus vac + sac)"
    );
    const capacitacionPercent = variables.find(
      (item) => item.item === "Capacitacion"
    );
    let calculoPrevisiones = 0;

    //sumas fijas
    let seguroVidaObj = montosCostosFijos.find(
      (item) => item.name === "Seguro de Vida"
    );
    let seguroVida = seguroVidaObj ? seguroVidaObj.amount : 0;

    let artFijoObj = montosCostosFijos.find((item) => item.name === "ART");
    let artFijo = artFijoObj ? artFijoObj.amount : 0;

    let totalCostosVariables;

    if (data.tipoContratacion === "Rel. de dependencia") {
      plusVacacional =
        diasVacaciones?.daysAmount === "14 días"
          ? sueldoBase * plusVacacional14.notPromotedPercent
          : sueldoBase * plusVacacional21.notPromotedPercent;

      sac = (sueldoBase + plusVacacional) * SACPercent.notPromotedPercent;

      obraSocial =
        (sueldoBase + plusVacacional + sac) *
        obraSocialPercent.notPromotedPercent;
      if (datosProyectos.tieneLEC === "si") {
        patronales =
          ((sueldoBase - detraccion.promotedPercent + plusVacacional + sac) *
            3.23) /
          100;
        asigFamiliares =
          ((sueldoBase - detraccion.promotedPercent + plusVacacional + sac) *
            1.41) /
          100;
        inssjp =
          (sueldoBase - detraccion.promotedPercent + plusVacacional + sac) *
          inssjpPercent.promotedPercent;
        fondoDesempleo =
          ((sueldoBase - detraccion.promotedPercent + plusVacacional + sac) *
            0.282) /
          100;
        tasasLec =
          (sueldoBase * ((3.23 + 1.41 + 0.48 + 0.282) * 4.5)) / 100 / 100;
      } else {
        patronales =
          ((sueldoBase - detraccion.promotedPercent + plusVacacional + sac) *
            10.77) /
          100;
        asigFamiliares =
          ((sueldoBase - detraccion.promotedPercent + plusVacacional + sac) *
            4.7) /
          100;
        inssjp =
          (sueldoBase - detraccion.promotedPercent + plusVacacional + sac) *
          inssjpPercent.notPromotedPercent;
        fondoDesempleo =
          ((sueldoBase - detraccion + plusVacacional + sac) * 0.94) / 100;
      }

      art = (sueldoBase + plusVacacional + sac) * artPercent.promotedPercent;
      pervIndemnizacionDespido =
        sueldoBase * pervIndemnizacionDespidoPercent.promotedPercent;
      prepagas = sueldoBase * prepagasPercent.promotedPercent;
      bonoPerm = sueldoBase * bonoPermPercent.promotedPercent;
      internet = sueldoBase * internetPercent.promotedPercent;
      guarderia = (sueldoBase * 0.25) / 100;
      capacitacion = sueldoBase * capacitacionPercent.promotedPercent;

      // Calculamos costos totales variables
      calculoContCariables =
        plusVacacional +
        sac +
        paquete +
        obraSocial +
        patronales +
        asigFamiliares +
        inssjp +
        fondoDesempleo +
        tasasLec +
        art;
      calculoPrevisiones =
        pervIndemnizacionDespido +
        prepagas +
        bonoPerm +
        internet +
        guarderia +
        capacitacion;
      totalCostosVariables =
        calculoContCariables + calculoPrevisiones + sueldoBase - sueldoBase;
    } else if (data.tipoContratacion === "Freelance") {
      pervIndemnizacionDespido =
        sueldoBase * pervIndemnizacionDespidoPercent.promotedPercent;
      prepagas = sueldoBase * prepagasPercent.promotedPercent;
      bonoPerm = sueldoBase * bonoPermPercent.promotedPercent;
      internet = sueldoBase * internetPercent.promotedPercent;
      capacitacion = sueldoBase * capacitacionPercent.promotedPercent;

      totalCostosVariables =
        paquete +
        pervIndemnizacionDespido +
        prepagas +
        bonoPerm +
        internet +
        capacitacion;
    }

    let sumaFija = artFijo + seguroVida;

    setVariableCosts(totalCostosVariables);
    setFixedCosts(sumaFija);
  };

  const handleAplicar = () => {
    const nuevoCostoUnitario = (variableCosts + fixedCosts) * data.cantidad;
    const idToUpdate = data.id;
    const nuevosDatos = datos.map((item) =>
      item.id === idToUpdate
        ? {
            ...item,
            costoUnitario: nuevoCostoUnitario,
            diasVacaciones: diasVacaciones,
            paqueteBeneficios: paqueteBeneficios,
            costosVariables: variableCosts,
            costosFijos: fixedCosts,
            valorHoraCosto:
              (data.sueldoBruto * data.cantidad + nuevoCostoUnitario) /
              data.cantidad /
              160,
          }
        : item
    );
    calculo(nuevosDatos);
    cancel(false);
  };

  const footer = (
    <div
      style={{ display: "flex", justifyContent: "end", marginRight: "68px" }}
    >
      <Button
        label="Cancelar"
        onClick={() => cancel(false)}
        className="boton-footer-cancelar-modal"
        style={{ color: "#535353", backgroundColor: "transparent" }}
      />
      <Button
        label="Aplicar"
        className="boton-footer-aplicar-modal"
        onClick={handleAplicar}
        disabled={
          data.tipoContratacion === "Rel. de dependencia" &&
          diasVacaciones === ""
        }
      />
    </div>
  );

  return (
    <>
      <Dialog
        draggable={false}
        closable={false}
        id="custom-bg-modal"
        style={{
          width: "709px",
          height: "405px",
          padding: "10px",
        }}
        visible={true}
        position={position}
        onHide={() => onHide("onHide")}
      >
        <div className="dialog-title">Modificar Costo</div>
        <div className="dialog-subtitle">
          A continuación, modificá los ítems que componen el costo.
        </div>
        <div className="dialog-content">
          <div className="p-field">
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "16px",
              }}
              htmlFor="benefitPackage"
            >
              {" "}
              Paquete de beneficios
            </label>
            <Dropdown
              className="campos-modal"
              optionLabel="name"
              label="name"
              value={
                beneficios.find(
                  (item) => item?.name === paqueteBeneficios?.name
                ) || null
              }
              options={beneficios}
              onChange={(e) => setPaqueteBeneficios(e.value)}
              placeholder="Seleccioná el paquete de beneficios"
            />
          </div>
          <div className="p-field">
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "16px",
              }}
              htmlFor="diasVacaciones"
            >
              Días de vacaciones
            </label>
            <Dropdown
              className="campos-modal"
              id="diasVacaciones"
              optionLabel="daysAmount"
              label="daysAmount"
              value={
                vacaciones.find(
                  (item) => item.daysAmount === diasVacaciones.daysAmount
                ) || null
              }
              options={vacaciones}
              onChange={(e) => setDiasVacaciones(e.value)}
              placeholder="Ingresá los días de vacaciones"
              disabled={data.tipoContratacion === "Freelance"}
            />
          </div>
          <div className="p-field">
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "16px",
              }}
              htmlFor="variableCosts"
            >
              Costos Variables
            </label>
            <InputNumber
              className="campos-modal"
              id="variableCosts"
              value={variableCosts}
              mode="currency"
              currency="ARS"
              locale="es-AR"
              readOnly
              disabled
            />
          </div>
          <div className="p-field">
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "16px",
              }}
              htmlFor="fixedCosts"
            >
              Costos Fijos
            </label>
            <InputNumber
              className="campos-modal"
              id="fixedCosts"
              value={fixedCosts}
              mode="currency"
              currency="ARS"
              locale="es-AR"
              readOnly
              disabled
            />
          </div>
        </div>
        {footer}
      </Dialog>
    </>
  );
};

export default ModalCostoUnitario;
